import { useState } from 'react'
import type { Brand } from '../../../../graphqlTypes'
import { useAfterMountEffect } from '../../../Shared/Hooks/useAfterMountEffect'
import { useOnInit } from '../../../Shared/Hooks/useOnInit'
import { asset } from '../../../Shared/asset'
import { GwmBrandSelect } from '../../Components/Gwm/GwmBrandSelect/GwmBrandSelect'
import { GwmModelSelect } from '../../Components/Gwm/GwmModelSelect/GwmModelSelect'
import { SectionWithHeadLine } from '../../Components/Gwm/SectionWithHeadLine/SectionWithHeadLine'
import { LoadingAnimation } from '../../Components/LoadingAnimation/LoadingAnimation'
import { Select } from '../../Components/Select/Select'
import { Arrow } from '../../Images/Arrow'
import AutoScout24Logo from '../../Images/AutoScout24Logo.svg'
import { CabrioIcon } from '../../Images/CabrioIcon'
import { ElektroIcon } from '../../Images/ElektroIcon'
import { HybridIcon } from '../../Images/HybridIcon'
import { KleinwagenIcon } from '../../Images/KleinwagenIcon'
import { KombiIcon } from '../../Images/KombiIcon'
import { LimousineIcon } from '../../Images/LimousineIcon'
import openerImage from '../../Images/Opener.svg'
import skyline from '../../Images/Skyline.svg'
import { SportwagenIcon } from '../../Images/SportwagenIcon'
import { SuvIcon } from '../../Images/SuvIcon'
import { TransporterIcon } from '../../Images/TransporterIcon'
import { VanIcon } from '../../Images/VanIcon'
import { useFetchCarmarketOffersCount } from '../../Shared/Hooks/useFetchCarmarketOffersCount'
import {
    extendedFilterOptions,
    initialRegistrationOptionsDefault,
    mileageOptionsDefault,
    priceOptions,
} from '../../Shared/helpers/carmarketDefaultOptions'
import { getQueryStringFromQueryObject } from '../../Shared/helpers/getQueryStringFromQueryObject'
import ZipRadius, { isZip } from '../GwmSearchTeaser/ZipRadius'
import popularBodyTypes from './stubs/popularBodyTypes.json'
import popularCars from './stubs/popularCars.json'

const HAS_NO_VALUE = -1

type LinkListGroup = {
    name: string
    url: string
    links: Link[]
}

type Link = {
    text: string
    url: string
}

export type GwmLandingPageProps = {
    brandsWithModels: Brand[]
}

export default function GwmLandingPage({ brandsWithModels }: GwmLandingPageProps) {
    return (
        <>
            <Search brandsWithModels={brandsWithModels} />

            <SectionWithHeadLine headline="Wonach suchen Sie?">
                <BodyType />
            </SectionWithHeadLine>

            <SectionWithHeadLine headline="Beliebte Gebrauchtwagen">
                <LinkList list={popularCars} />
            </SectionWithHeadLine>

            <SectionWithHeadLine headline="Beliebte Fahrzeugtypen">
                <LinkList list={popularBodyTypes} />
            </SectionWithHeadLine>
        </>
    )
}

const getFilterQuery = ({
    brand,
    initialRegistration,
    mileageTo,
    model,
    priceMax,
    fuel,
    zip,
    radius,
}: {
    brand: string
    initialRegistration: string
    mileageTo: string
    model: string
    priceMax: string
    fuel: string
    zip: string
    radius: string
}) => {
    const parameters = new URLSearchParams()
    if (brand !== '-1') {
        parameters.set('brand', brand)
    }
    if (model !== '-1') {
        parameters.set('model', model)
    }
    if (initialRegistration !== '-1') {
        parameters.set('initial-registration-from', initialRegistration)
    }
    if (mileageTo !== '-1') {
        parameters.set('mileage-to', mileageTo)
    }
    if (priceMax !== '-1') {
        parameters.set('price-to', priceMax)
    }
    if (fuel !== '-1') {
        parameters.set('fuel[]', fuel)
    }
    if (isZip(zip)) {
        parameters.set('zip', zip)
        parameters.set('zip-range', radius)
    }
    const query = parameters.toString()
    return query ? '?' + query : ''
}

function Search({ brandsWithModels }: { brandsWithModels: Brand[] }) {
    const [brand, setBrand] = useState<string>('-1')
    const [model, setModel] = useState<string>('-1')
    const [zip, setZip] = useState('')
    const [radius, setRadius] = useState('50')
    const [priceMax, setPriceMax] = useState<string>('-1')
    const [initialRegistration, setInitialRegistration] = useState<string>('-1')
    const [fuel, setFuel] = useState<string>('-1')
    const [mileageTo, setMileageTo] = useState<string>('-1')

    const { fetchOfferCount, totalNumberOfOffers, totalNumberOfOffersLoading } =
        useFetchCarmarketOffersCount(HAS_NO_VALUE)

    useOnInit(() => {
        const query = getQueryStringFromQueryObject({})
        fetchOfferCount(query)
    })

    useAfterMountEffect(
        () =>
            fetchOfferCount(
                getFilterQuery({
                    brand,
                    model,
                    zip,
                    radius,
                    priceMax,
                    fuel,
                    mileageTo,
                    initialRegistration,
                }),
            ),
        [fetchOfferCount, brand, model, zip, radius, priceMax, fuel, mileageTo, initialRegistration],
    )

    return (
        <div className="relative mb-25 block w-full overflow-hidden bg-tarmac-25 p-12 md:mb-32 md:overflow-visible md:pb-30 md:pl-30 md:pr-0 md:pt-30">
            <div className="mb-17 md:relative md:mb-24 md:flex md:flex-row md:justify-between">
                <div className="flex w-full justify-center text-center md:absolute md:-bottom-7 md:right-0 md:order-2 md:mr-30 md:w-auto">
                    <div className="font-sans text-12 font-normal leading-28 text-tarmac-200">Ein Service von</div>
                    <a
                        href="https://www.autoscout24.de/"
                        target="_blank"
                        rel="nofollow noopener"
                        className="ml-6 inline cursor-pointer p-0"
                    >
                        <img
                            className="inline h-18 w-auto md:h-20"
                            src={asset(AutoScout24Logo)}
                            alt="Ein Service von AutoScout24"
                            width="76"
                            height="18"
                        />
                    </a>
                </div>
                <h1 className="text-center font-sans text-28 font-extrabold leading-36 text-[#1166a8] md:order-1 md:w-auto md:text-36 md:leading-28">
                    Top-Gebrauchtwagen bei AUTO BILD
                </h1>
            </div>
            <div className="flex w-full flex-col md:flex-row">
                <div className="mb-4 w-full items-center bg-white px-12 pb-12 pt-8 md:z-[1] md:mb-0 md:flex md:w-[46rem] md:flex-col md:p-20">
                    <div className="flex w-full flex-col gap-x-16 md:mb-11 md:flex-row md:gap-x-20">
                        <GwmBrandSelect
                            className="mb-8 w-full md:mb-0"
                            brands={brandsWithModels}
                            value={brand}
                            stateDispatcher={(brand) => {
                                setBrand(brand)
                                setModel('-1')
                            }}
                            data-testid="brand-filter"
                        />
                        <div className="mb-8 w-full md:mb-0">
                            <GwmModelSelect
                                brand={brand}
                                brands={brandsWithModels}
                                value={model}
                                stateDispatcher={setModel}
                                data-testid="model-filter"
                            />
                        </div>
                    </div>

                    <div className="flex w-full flex-row gap-x-16 md:mb-11 md:gap-x-20">
                        <Select
                            className="mb-12 w-full md:mb-0"
                            label={'Preis bis'}
                            options={priceOptions}
                            value={priceMax}
                            stateDispatcher={setPriceMax}
                            data-testid="price-to-filter"
                        ></Select>
                        <Select
                            className="mb-12 w-full md:mb-0"
                            label="Erstzulassung ab"
                            options={initialRegistrationOptionsDefault}
                            value={initialRegistration}
                            stateDispatcher={setInitialRegistration}
                            data-testid="initial-registration-from-filter"
                        />
                    </div>

                    <div className="flex w-full flex-row gap-x-16 md:mb-11 md:gap-x-20">
                        <Select
                            className="mb-12 w-full md:mb-0"
                            label={'Kilometer bis'}
                            options={mileageOptionsDefault}
                            value={mileageTo}
                            stateDispatcher={setMileageTo}
                            data-testid="mileage-to-filter"
                        ></Select>
                        <Select
                            className="mb-12 w-full md:mb-0"
                            label="Kraftstoff"
                            options={[{ name: 'Beliebig', value: '-1' }, ...extendedFilterOptions.fuelOptions]}
                            value={fuel}
                            stateDispatcher={setFuel}
                            data-testid="fuel-filter"
                        />
                    </div>

                    <ZipRadius
                        className="gap-x-16 md:w-full md:gap-x-20"
                        zip={zip}
                        setZip={setZip}
                        radius={radius}
                        setRadius={setRadius}
                    />

                    <a
                        className="group mt-24 flex w-full flex-row items-center justify-center bg-tarmac-400 p-13 text-center text-14 font-bold uppercase text-white hover:cursor-pointer hover:bg-[#f5f200] hover:text-tarmac-500 md:mt-20 md:w-fit md:min-w-[23rem] md:px-15 md:py-14 md:text-18"
                        href={`${
                            import.meta.env.VITE_GWM_URL ?? 'https://gebrauchtwagen.autobild.de'
                        }/ergebnisse/${getFilterQuery({
                            brand,
                            model,
                            zip,
                            radius,
                            priceMax,
                            fuel,
                            mileageTo,
                            initialRegistration,
                        })}`}
                        data-testid="ctaElement"
                    >
                        <span className="mr-9 leading-20 md:mr-12 md:leading-29">
                            <LoadingAnimation
                                isLoading={totalNumberOfOffers === HAS_NO_VALUE || totalNumberOfOffersLoading}
                                className="bg-white group-hover:bg-tarmac-500"
                            >
                                {`${totalNumberOfOffers.toLocaleString('de-DE')} Angebote`}
                            </LoadingAnimation>
                        </span>
                        <Arrow className="h-20 w-10 md:h-29 md:w-15" />
                    </a>

                    <a
                        className="mx-auto mt-12 block w-fit font-sans text-16 font-normal leading-26 text-tarmac-500 hover:text-red-light-500 md:-mb-8"
                        rel="nofollow"
                        href={`${
                            import.meta.env.VITE_GWM_URL ?? 'https://gebrauchtwagen.autobild.de'
                        }/suchen/${getFilterQuery({
                            brand,
                            model,
                            zip,
                            radius,
                            priceMax,
                            fuel,
                            mileageTo,
                            initialRegistration,
                        })}`}
                    >
                        <span className="mr-8 inline-block h-12 w-8 bg-red-light-500"></span>
                        Fahrzeug-Detailsuche
                    </a>
                </div>
                <div className="relative block h-[26.3rem] w-full justify-center md:flex md:h-[42.2rem] md:w-auto">
                    <div
                        className="-ml-12 h-[11.4rem] w-[70.9rem] bg-no-repeat md:z-0 md:-ml-[49rem] md:h-[16.3rem] md:w-[101rem] md:max-w-[101rem]"
                        style={{
                            backgroundImage: `url("${asset(skyline)}")`,
                        }}
                    ></div>
                    <img
                        className="max-w-auto absolute bottom-2 left-0 right-0 mx-auto block h-[18.4rem] max-h-[18.4rem] w-[31.2rem] md:bottom-0 md:left-auto md:right-auto md:h-[29.1rem] md:max-h-[29.1rem] md:w-[49.4rem] md:max-w-[49.4rem]"
                        src={asset(openerImage)}
                        alt="Aufmachergrafik Auto"
                        width="312"
                        height="184"
                    />
                </div>
            </div>
        </div>
    )
}

function BodyType() {
    return (
        <ul className="flex flex-wrap items-end justify-center gap-x-16 gap-y-16 md:mx-30 md:gap-x-48">
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/cabrio/`}
                    aria-label="Fahrzeugtyp Cabrio"
                    title="Alle gebrauchten Cabrios anzeigen"
                >
                    <CabrioIcon className="mb-10 h-32 w-86" />
                    Cabrio
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/coupe/`}
                    aria-label="Fahrzeugtyp Coupé"
                    title="Alle gebrauchten Coupés anzeigen"
                >
                    <SportwagenIcon className="mb-10 h-30 w-88" />
                    Coupé
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/elektro/`}
                    aria-label="Fahrzeugtyp Elektro"
                    title="Alle gebrauchten Elektrofahrzeuge anzeigen"
                >
                    <ElektroIcon className="mb-10 mr-20 overflow-visible" />
                    Elektro
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/hybrid/`}
                    aria-label="Fahrzeugtyp Hybrid"
                    title="Alle gebrauchten Hybridfahrzeuge anzeigen"
                >
                    <HybridIcon className="mb-10 mr-20 overflow-visible" />
                    Hybrid
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/kleinwagen/`}
                    aria-label="Fahrzeugtyp Kleinwagen"
                    title="Alle gebrauchten Kleinwagen anzeigen"
                >
                    <KleinwagenIcon className="mb-10 h-34 w-76" />
                    Kleinwagen
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/kombi/`}
                    aria-label="Fahrzeugtyp Kombi"
                    title="Alle gebrauchten Kombis anzeigen"
                >
                    <KombiIcon className="mb-10 h-35 w-86" />
                    Kombi
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/limousine/`}
                    aria-label="Fahrzeugtyp Limousine"
                    title="Alle gebrauchten Limousinen anzeigen"
                >
                    <LimousineIcon className="mb-10 h-35 w-85" />
                    Limousine
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/suv/`}
                    aria-label="Fahrzeugtyp SUV"
                    title="Alle gebrauchten SUVs anzeigen"
                >
                    <SuvIcon className="mb-10 h-38 w-88" />
                    SUV
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/transporter/`}
                    aria-label="Fahrzeugtyp Transporter"
                    title="Alle gebrauchten Transporter anzeigen"
                >
                    <TransporterIcon className="mb-10 h-43 w-82" />
                    Transporter
                </a>
            </li>
            <li className="flex w-[10.4rem] justify-center md:w-90">
                <a
                    className="group flex flex-col items-center text-16 leading-[2.179rem] hover:text-[#1A72E8]"
                    href={`${import.meta.env.VITE_GWM_URL}/a-z/van/`}
                    aria-label="Fahrzeugtyp Van"
                    title="Alle gebrauchten Vans anzeigen"
                >
                    <VanIcon className="mb-10 h-39 w-90" />
                    Van
                </a>
            </li>
        </ul>
    )
}

function LinkList({ list }: { list: LinkListGroup[] }) {
    return (
        <div className="grid grid-cols-2 gap-x-16 gap-y-8 md:grid-cols-4 md:gap-x-30">
            {list.map((items) => (
                <div className="mb-4 md:mb-8" key={items.name}>
                    <div className="mb-4 border-b-1 border-tarmac-75 pb-3 font-bold leading-25 md:mb-6">
                        {items.url ? (
                            <a
                                href={`${import.meta.env.VITE_GWM_URL}${items.url}`}
                                className="text-16 uppercase text-tarmac-500 hover:text-[#1A72E8]"
                                title={`Alle gebrauchten ${items.name} anzeigen`}
                            >
                                {items.name}
                            </a>
                        ) : (
                            <span className="text-16 uppercase text-tarmac-500">{items.name}</span>
                        )}
                    </div>
                    <ul className="flex flex-col md:gap-y-2">
                        {items.links.map((link) => (
                            <li className="flex items-center" key={link.text}>
                                <a
                                    className="my-3 text-16 leading-22 text-[#1166A8] hover:text-[#1A72E8] hover:underline"
                                    href={`${import.meta.env.VITE_GWM_URL}${link.url}`}
                                    title={`Alle gebrauchten ${link.text} anzeigen`}
                                >
                                    {link.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}
